<template>
  <default-layout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{ $store.state.pageIcon }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
    </template>
    <v-container class="px-0 pt-6" fluid>
      <v-card elevation="0">
        <v-img :src="require('@/assets/img/logo.jpg')" width="100px" class="mx-auto" contain></v-img>
        <v-card-text class="text-center">
          <h1>Sistem Informasi Sekolah</h1>
          <p>Versi Demo</p>
          <p>
             <b>Hubungi Kami :</b> <br>Romi (+62)87878782131 | Yuli (+62)811805484 
          </p>
          <p>
             <b>Email :</b> <br>sales@idgi.co.id
          </p>
          <p>
             <b>Alamat :</b> <br>Kinanti Building Lt. 2. Jl. Epicentrum Tengah No. 3. Karet Kuningan Setiabudi. Jakarta Selatan 12940
          </p>
        </v-card-text>
      </v-card>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
export default {

  name: "About",
  components: {
    DefaultLayout,
  },

  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.setPageTitle("About", "mdi-book-open-page-variant");
  },
}
</script>